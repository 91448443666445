@tailwind base;
@tailwind components;
@tailwind utilities;

/* Mobile First Approach */
.App {
  width: 100%; /* Take full width on mobile */
  max-width: 100%; /* Take full width on mobile */
  margin: 0 auto;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

/* Styling for the clickable header link */
.header-link {
  color: white;
  text-decoration: none;
}

.header-link:hover {
  text-decoration: underline;
}

.App-header {
  background-color: #05846a;
  padding: 1rem;
  color: white;
  text-align: center;
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  flex-wrap: wrap;
}

.top-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

h1 {
  margin: 0;
  font-size: 1.2rem; 
}

@media (min-width: 48rem) {
  .App {
    width: calc(100% - 30rem);
    max-width: calc(100% - 30rem);
    padding: 0;
  }

  .App-header {
    text-align: left;
  }

  .top-bar {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .domain-filter {
    width: 18%;
    max-width: 40%;
  }

  button {
    width: auto;
    margin-left: 1rem;
  }
}

.top-bar .domain-filter {
  width: 100%;
  max-width: 150%;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ffffff; 
  color: #333; 
  transition: all 0.3s ease; 
}

.top-bar .domain-filter:hover {
  border-color: #05846a; 
}

.top-bar .domain-filter:focus {
  outline: none;
  border-color: #05846a; 
}

.top-bar .domain-filter option {
  background-color: #ffffff; 
  color: #333; 
}

.top-bar .domain-filter option:hover {
  background-color: #f1f1f1; 
}

/* Tailwind-specific custom adjustments */

@media (min-width: 640px) {
  /* For small screens and above */
  .top-bar {
    display: flex;
    justify-content: space-between;
  }
}

/* Domain select box */
.domain-filter {
  width: 100%;
  max-width: 200px; /* Adjust max-width for larger screens */
}
