.auth-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.auth-container h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.auth-container input {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.auth-container button {
  width: 100%; 
  padding: 0.75rem;
  background-color: #05846a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center; 
  box-sizing: border-box; 
  margin: 0; 
}

.auth-container button:hover {
  background-color: #046f56;
}
